$primary-background-color: #29303a;
$white: #ffffff;
$sesh-color: #00f4ff;
$dark-gray: #1f242b;
$dark-gray-hover: lighten($dark-gray, 10%);
$premium: #e2e608;

$text: $white;

$primary: $sesh-color;

$secondary-gray: #98a3b2;

$sesh-color-hover: darken($sesh-color, 10%);

$light-gray: darken($white, 20%);

$secondary-nav-text-color: $secondary-gray;
$nav-bar-sesh-title-size: 2.5em;
$error-color: #fa461c;

// Bulma's global variables
$family-sans-serif: "Open Sans", sans-serif;

@import "../../node_modules/bulma/sass/utilities/_all.sass";

$spacing-shortcuts: ();
$spacing-horizontal: null;
$spacing-vertical: null;
$spacing-values: ();

@import "./tailwind.css";

ul {
	list-style-type: none;
	margin: 0;
}

a {
	color: inherit;
}

.title {
	font-weight: 700;
}

.base-button {
	font-weight: bold;
	border-radius: 0 !important;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
}

.dark-button-style {
	background-color: $dark-gray !important;
	color: $white !important;
	border: 2px solid $sesh-color !important;
}

.warning-button-style {
	background-color: rgba(0, 0, 0, 0) !important;
	color: red !important;
	border: 2px solid red !important;
}

.premium-button-style {
	color: $premium !important;
	background-color: rgba(0, 0, 0, 0) !important;
	border: 2px solid $premium !important;
}

.premium-button {
	@extend .base-button;
	@extend .premium-button-style;

	animation: pulsate 2s ease-in-out infinite;

	@keyframes pulsate {
		0% {
			box-shadow: 0px 0px 20px 6px rgba($premium, 0.2);
		}
		50% {
			box-shadow: 0px 0px 20px 6px rgba($premium, 0.1);
		}
		100% {
			box-shadow: 0px 0px 20px 6px rgba($premium, 0.2);
		}
	}

	&:hover {
		color: $primary-background-color !important;
		background-color: $premium !important;
	}
}

.dark-button {
	@extend .base-button;
	@extend .dark-button-style;
}

.warning-button {
	@extend .base-button;
	@extend .warning-button-style;
}

.warning-button-hover {
	background-color: red !important;
	color: black !important;
}

.warning-button:hover:enabled {
	@extend .warning-button-hover;
}

.dark-button-hover {
	background-color: $dark-gray-hover !important;
}

.dark-button:hover:enabled {
	@extend .dark-button-hover;
}

.primary-button {
	@extend .base-button;

	background-color: $sesh-color !important;
	color: $dark-gray !important;
	border: none !important;
}

.primary-button:hover {
	background-color: $sesh-color-hover !important;
}

.mock-bar {
	position: relative !important;
	display: block;
	z-index: -1 !important;
	box-shadow: none !important;
}

.command-example {
	font-family: monospace;
	background-color: #181c22;
	line-height: 0.9rem;

	border-radius: 4px;

	&--inline {
		font-family: monospace;
		background-color: #181c22;
		padding-left: 0.2em;
		padding-right: 0.2em;
		border-radius: 4px;
	}
}

.command-example:not(:last-child) {
	padding-bottom: 0.25rem;
}

.topnav {
	background-color: $primary-background-color;
	position: fixed;
	width: 100%;
	z-index: 10;

	top: 0%;
	left: 0%;

	&--static {
		@extend .topnav;
		position: relative !important;
		display: block !important;
	}

	font-size: 1em;

	@include mobile {
		font-size: 0.8em;
	}

	padding-top: 1em;
	padding-bottom: 1em;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

	border-bottom: 1px solid $dark-gray;

	@include mobile {
		.icon {
			font-size: 1.5em;
		}
	}

	.sesh-dropdown-item {
		a {
			font-weight: inherit;
			font-size: 1em;
		}
	}

	a {
		text-align: center;
		color: $secondary-nav-text-color;
		text-decoration: none;
		font-weight: bold;

		@include mobile {
			font-size: 1em;
		}

		&:hover {
			color: $sesh-color;
		}
	}

	.sesh-title {
		color: $sesh-color;
		font-size: $nav-bar-sesh-title-size;
	}

	.manual-link {
		background-color: $white;

		.button-icon {
			margin-right: 0.5rem !important;
			margin-left: -0.25rem !important;
		}

		padding-top: 0.75em;
		padding-bottom: 0.75em;
		padding-left: 1.5em;
		padding-right: 1.5em;
		//margin: 0.25em;
		color: $dark-gray !important;

		&:hover {
			background-color: $sesh-color;
			color: $dark-gray !important;
		}
	}
	// a:hover {
	// }

	// a.active {
	// }

	.topnav-right {
		margin-left: auto;
	}
}

.white-link {
	//text-decoration: underline;
	color: $white;
	font-weight: lighter;
}

.white-link:hover {
	color: darken($white, 10%);
}

.tooltip-v2 {
	color: $text;
	padding: 1rem;
	border: 1px solid $sesh-color-hover;
	background-color: $dark-gray;

	bottom: 100%;

	box-sizing: border-box !important;

	overflow: visible !important;

	.tooltip-arrow {
		z-index: 21;
		position: absolute;
		top: -webkit-calc(100%); /*may require prefix for old browser support*/
		top: calc(100% - 4px); /*i.e. half the height*/
		border-bottom: inherit;
		border-right: inherit;
		box-shadow: none;

		left: 0;

		height: 8px;
		width: 8px;
		background: inherit;
		box-sizing: border-box !important;
		transform: rotate(45deg);
	}
}

footer {
	vertical-align: center;

	background-color: $primary-background-color;

	padding-top: 4em !important;
	padding-bottom: 4em !important;
	margin-bottom: 0 !important;

	@include mobile {
		br {
			margin-bottom: 1em;
		}
	}

	.icon {
		font-size: 1.5em;
		margin-right: 0.5em;
		vertical-align: middle;
		color: $sesh-color;
	}
}
